import http from "../http";
import InputModel from "@/models/inputFieldModel";
import InputFieldComputeModel from "@/models/inputFieldComputeModel";

const ENDPOINT = "documents";

const getCurrentUserDocuments = () =>
  http.get(`${ENDPOINT}/mine`, { forceUpdate: true , params: { isRead: false } });

const getDocumentById = (documentId: string) =>
  http.get(`${ENDPOINT}/${documentId}`);

const sortBaseOnRequired = (
  a: InputFieldComputeModel,
  b: InputFieldComputeModel
) => {
  return a.required > b.required ? -1 : 1;
};

const buildForm = (fields: Array<InputFieldComputeModel>) => {
  fields.sort(sortBaseOnRequired);
  const result = fields.map((item) => {
    const element = {
      name: item.name,
      code: item.code,
      required: item.required,
      prefilled: item.prefilled,
    } as InputModel;

    if (typeof item.type == "string") {
      element.type = item.type;
      element.value = "";
      if (element.type == "date") {
        const today = new Date();
        const year = today.getFullYear();
        const month = today.getMonth() + 1;
        const monthDay = today.getDate();

        const stringMonth = month < 10 ? "0" + month : month.toString();
        const stringMonthDay =
          monthDay < 10 ? "0" + monthDay : monthDay.toString();

        const finalDate = year + "-" + stringMonth + "-" + stringMonthDay;
        element.value = finalDate;
      }
      element.placeholder = "Enter " + item.name;
    } else {
      element.type = "select";
      element.value = null;
      const option = item.type.map((item: string) => {
        return { value: item, text: item };
      });
      element.option = [
        { value: null, text: "Please select a currency" },
        ...option,
      ];
    }
    return element;
  });
  return result;
};

// const subscribeToNotification = () => {
//   const userToken = localStorage.getItem("notification_token");
//   return http.post(`${ENDPOINT}/add_user_to_notification_channel`, userToken);
// };

export default {
  getCurrentUserDocuments,
  getDocumentById,
  buildForm,
  // subscribeToNotification,
  sortBaseOnRequired,
};
